<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Padronização</p>
        <div class="container">
            <h2 class="page_header_title">Padronização</h2>
        </div>
    </div>
</div>

<div *ngIf="!isNullOrUndefined(standardizationDetails.gasStation)">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-5">
                <h4 class="home_title mt-5 mb-5 text-uppercase" style="line-height:1.2">
                    Capriche nas fotos e faça bonito na Visita Técnica</h4>
            </div>
            <div class="col-sm-6 offset-lg-1">
                <div class="technical-detail_card p-4 mb-5">
                    <div class="row">
                        <dl class="col-auto">
                            <dt class="technical-detail_lbl">CNPJ</dt>
                            <dd class="technical-detail_value">{{standardizationDetails.standarlization?.cnpj|mask:'00.000.000/0000-00'}}</dd>
                        </dl>
                        <dl class="col">
                            <dt class="technical-detail_lbl">Razão Social</dt>
                            <dd class="technical-detail_value">{{standardizationDetails.companyName}}</dd>
                        </dl>
                        <dl class="col-auto">
                            <div class="row mx-n1 align-items-center">
                                <div class="col-4 px-1">
                                    <svg-icon [src]="caseStatusIcon(standardizationDetails.status)"></svg-icon>
                                </div>
                                <div class="col-auto px-1">
                                    <dt class="technical-detail_lbl">Status</dt>
                                    <dd class="technical-detail_value">{{standardizationDetails.status}}</dd>
                                </div>
                            </div>

                        </dl>
                    </div>
                    <div class="row mt-3" style="row-gap:20px">
                        <div class="col-sm-6">
                            <a class="btn w-100 h-100 align-text-center"
                               [ngClass]="gasStationInput.gasStation == false ? 'btn-white' :'btn-outline-primary'"
                               (click)="handlerGasStation(false)">
                                <div class="row justify-content-center align-items-center h-100">
                                    <div class="col-auto d-none"
                                         [ngClass]="{'d-block':gasStationInput.gasStation  == false}">
                                        <svg-icon src="assets/svg/check2.svg"></svg-icon>
                                    </div>
                                    <div class="col-auto">
                                        <span>Revenda</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6">
                            <a class="btn w-100 h-100 align-text-center"
                               [ngClass]="gasStationInput.gasStation ?'btn-white' : 'btn-outline-primary'"
                               (click)="handlerGasStation(true)">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-auto d-none"
                                         [ngClass]="{'d-block':gasStationInput.gasStation == true}">
                                        <svg-icon src="assets/svg/check2.svg"></svg-icon>
                                    </div>
                                    <div class="col-auto">
                                        <span>Postos ou Supermercados</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-5">
                <h2 class="standardization_title mb-3">Cupons</h2>
                <div class="row" style="row-gap: 20px">
                    <div class="col-sm-4">
                        <div class="coupon_card" [ngClass]="{'card-active':userCoupon.facade}">
                            <img class="coupon_img" [ngClass]="{'img-active':userCoupon.facade}"
                                 src="assets/images/logo-dark.png" alt="logo-dark"/>
                            <p class="coupon_lbl" [ngClass]="{'lbl-active':userCoupon.facade}">Fachada</p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="coupon_card" [ngClass]="{'card-active':userCoupon.fleet}">
                            <img class="coupon_img" [ngClass]="{'img-active':userCoupon.fleet}"
                                 src="assets/images/logo-dark.png" alt="logo-dark"/>
                            <p class="coupon_lbl" [ngClass]="{'lbl-active':userCoupon.fleet}">Frota</p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="coupon_card" [ngClass]="{'card-active':userCoupon.extra}">
                            <img class="coupon_img" [ngClass]="{'img-active':userCoupon.extra}"
                                 src="assets/images/logo-dark.png" alt="logo-dark"/>
                            <p class="coupon_lbl" [ngClass]="{'lbl-active':userCoupon.extra}">Extra</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 offset-lg-1">
                <h2 class="standardization_title mb-3">Prêmios</h2>
                <p class="home_text">
                    Todas as revendas que comprovarem a padronização da fachada e de sua frota, ganham cupons para o
                    sorteio
                    de até R$ 10 mil em carga de gás.
                </p>
                <p class="home_text">
                    Revendas sem fachada do tipo posto ou supermercados, não participam do sorteio.
                </p>

            </div>
        </div>
    </div>
    <app-standardization
            [standardizationPage]="true"
            [(standardization)]="standardizationDetails.standarlization"
            (handlerChildren)="refetchDetail()"
            (saveStandardization)="saveReport()"
            [gasStation]="standardizationDetails.gasStation"
            [status]="standardizationDetails.status"></app-standardization>

</div>

<div *ngIf="isNullOrUndefined(standardizationDetails.gasStation)">
    <div class="container text-center" *ngIf="true">
        <div class="wait-panel">
        </div>
    </div>
</div>


<app-modal-gas-station [gasStationInput]="gasStationInput"
                       (eventSend)="refetchDetail()"></app-modal-gas-station>

<div class="modal fade" id="modalGasStationStandPage" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width: 560px">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title"></h3>
            </div>
            <div class="modal-body pt-0 bg-secondary">
                <h3 class="modal-title modal_title text-uppercase text-center mb-4"
                    style="max-width: 372px;margin: 0 auto;">
                    defina qual é o tipo da sua revenda
                </h3>
                <div class="row mb-4">
                    <dl class="col-auto">
                        <dt class="modal_value text-white mb-0 pr-2">CNPJ</dt>
                        <dd class="modal_lbl text-white mb-0">{{standardizationDetails.standarlization?.cnpj|mask:'00.000.000/0000-00'}}</dd>
                    </dl>
                    <dl class="col">
                        <dt class="modal_value text-white mb-0 pr-2">Razão Social</dt>
                        <dd class="modal_lbl text-white mb-0">{{standardizationDetails.companyName}}</dd>
                    </dl>
                    <dl class="col-auto">
                        <div class="row mx-n1 align-items-center">
                            <div class="col-4 px-1">
                                <svg-icon [src]="caseStatusIcon(standardizationDetails.status)"></svg-icon>
                            </div>
                            <div class="col-auto px-1">
                                <dt class="modal_value text-white mb-0 pr-2">Status</dt>
                                <dd class="modal_lbl text-white mb-0">{{standardizationDetails.status}}</dd>
                            </div>
                        </div>

                    </dl>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <a class="btn w-100 h-100 align-text-center btn-outline-white"
                           [ngClass]="{'btn-white': gasStationInput.gasStation == false}"
                           (click)="gasStationInput.gasStation = false">
                            <span>Revenda</span>
                        </a>
                    </div>
                    <div class="col-sm-6">
                        <a class="btn w-100 h-100 align-text-center btn-outline-white"
                           [ngClass]="{'btn-white': gasStationInput.gasStation}"
                           (click)="gasStationInput.gasStation = true">
                            <span>Postos ou Supermercados</span>
                        </a>
                    </div>
                </div>

                <div class="mt-5" *ngIf="!isNullOrUndefined(this.gasStationInput.gasStation)">
                    <div class="row align-items-center no-gutters border-bottom border-white pb-3 mb-3"
                         *ngIf="this.gasStationInput.gasStation">
                        <div class="col-sm">
                            <span class="text-white">Possui Testeira?</span>
                        </div>
                        <div class="col-sm-4">
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <a (click)="gasStationInput.isFacade = true"
                                       [ngClass]="{'bg-white text-primary':gasStationInput.isFacade}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Sim</span>
                                    </a>
                                </div>
                                <div class="col px-1">
                                    <a (click)="gasStationInput.isFacade = false"
                                       [ngClass]="{'bg-white text-primary':gasStationInput.isFacade == false}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Não</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center no-gutters">
                        <div class="col-sm">
                            <span class="text-white">Possui Frota?</span>
                        </div>
                        <div class="col-sm-4">
                            <div class="row mx-n1">
                                <div class="col px-1">
                                    <a (click)="gasStationInput.isFleet = true"
                                       [ngClass]="{'bg-white text-primary':gasStationInput.isFleet}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Sim</span>
                                    </a>
                                </div>
                                <div class="col px-1">
                                    <a (click)="gasStationInput.isFleet = false"
                                       [ngClass]="{'bg-white text-primary':gasStationInput.isFleet == false}"
                                       class="btn btn-block btn-outline-white">
                                        <span>Não</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center mt-5">
                    <div class="col-sm-7">
                        <a class="btn w-100 h-100 align-text-center btn-white" (click)="changeGasStation()">
                            <span>Salvar</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {UserComponent} from './views/user/user.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {LoginComponent} from './views/login/login.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {NgxMaskModule} from "ngx-mask";
import {ProfileComponent} from './views/profile/profile.component';
import {FooterComponent} from './views/footer/footer.component';
import {PrizeDetailComponent} from './views/prize-detail/prize-detail.component';
import {RankingComponent} from './views/ranking/ranking.component';
import {PrizesComponent} from './views/prizes/prizes.component';
import {TechnicalVisitComponent} from './views/technical-visit/technical-visit.component';
import {CampaignComponent} from './views/campaign/campaign.component';
import {EngagedResellerComponent} from './views/engaged-reseller/engaged-reseller.component';
import {NewsComponent} from './views/news/news.component';
import {RankingListExtraComponent} from './views/ranking-list-extra/ranking-list-extra.component';
import {RankingDetailComponent} from './views/ranking-detail/ranking-detail.component';
import {RecoveryComponent} from './views/recovery/recovery.component';
import {FaqComponent} from './views/faq/faq.component';
import {ContactComponent} from './views/contact/contact.component';
import {CropImageComponent} from './views/crop-image/crop-image.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DownloadsComponent} from './views/downloads/downloads.component';
import {LogoutComponent} from "./views/logout/logout.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {NewsDetailComponent} from "./views/news-detail/news-detail.component";
import {PrizesEqComponent} from './views/prizes/prizes-eq/prizes-eq.component';
import {PrizesRvComponent} from './views/prizes/prizes-rv/prizes-rv.component';
import {PaginationCustomModule} from "./views/pagination-custom/pagination-custom.module";
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { LgpdComponent } from './views/lgpd/lgpd.component';
import { DoubtsComponent } from './views/doubts/doubts.component';
import {WinnersComponent} from './views/winners/winners.component';
import { TechnicalVisitDetailComponent } from './views/technical-visit-detail/technical-visit-detail.component';
import { StandardizationComponent } from './views/standardization/standardization.component';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import { StandardizationPageComponent } from './views/standardization-page/standardization-page.component';
import { ChatComponent } from './views/chat/chat.component';
import { ModalGasStationComponent } from './views/modal-gas-station/modal-gas-station.component';
import { BoardgameComponent } from './views/boardgame/boardgame.component';

registerLocaleData(localePt, 'pt-BR');

export function playerFactory() {
    return player;
}


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        HomeComponent,
        AutocompleteComponent,
        UserComponent,
        LoginComponent,
        ProfileComponent,
        FooterComponent,
        PrizeDetailComponent,
        RankingComponent,
        PrizesComponent,
        TechnicalVisitComponent,
        CampaignComponent,
        EngagedResellerComponent,
        NewsComponent,
        RankingListExtraComponent,
        RankingDetailComponent,
        RecoveryComponent,
        FaqComponent,
        ContactComponent,
        DownloadsComponent,
        CropImageComponent,
        LogoutComponent,
        NewsDetailComponent,
        PrizesEqComponent,
        PrizesRvComponent,
        PrivacyPolicyComponent,
        LgpdComponent,
        DoubtsComponent,
        WinnersComponent,
        TechnicalVisitDetailComponent,
        StandardizationComponent,
        StandardizationPageComponent,
        ChatComponent,
        ModalGasStationComponent,
        BoardgameComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        GraphQLModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        NgxMaskModule.forRoot(),
        ImageCropperModule,
        InfiniteScrollModule,
        PaginationCustomModule,
        HammerModule,
        [LottieModule.forRoot({player: playerFactory})]
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.'
            }
        },
        TranslateService,
        AppDataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Time Preparado</p>
        <div class="container">
            <h2 class="page_header_title">Time <span>Preparado</span></h2>
        </div>
    </div>
</div>

<div class="bg-secondary d-none">
    <div class="container">
        <img style="width: 100%" src="assets/images/banner/time-preparado-fev.png" alt="banner">
    </div>
</div>

<div class="container mt-5">
    <h1 class="home_title home_title-lg">TREINAMENTO |<span> Videos e Quiz</span></h1>
    <div class="row my-5" style="row-gap: 30px">
        <div class="col-lg-auto">
            <div class="position-relative h-100">
                <div class="d-flex flex-lg-column h-100 mx-n1 justify-content-between align-content-center"
                     style="row-gap: 30px">
                    <a class="px-1 position-relative" *ngFor="let x of listOfMonths"
                       (click)="handlerTraining(x)">
                        <div class="home-training_btn" [ngClass]="{'active':x.id === stepTraining}">
                            <p class="home-training_lbl">{{x.name}}</p>
                            <p class="home-training_lbl home-training_lbl-sm">{{x.date}}</p>
                        </div>
                        <svg-icon
                                class="home-training_arrow home-training_arrow-engaged home-training_arrow"
                                [ngClass]="{'active':x.id == stepTraining}"
                                src="assets/svg/arrow-training.svg"></svg-icon>
                    </a>
                </div>
                <div class="home-training_bar home-training_bar-engaged">&nbsp;</div>
            </div>
        </div>
        <div class="col-lg pl-lg-5">
            <div class="row pl-lg-4" style="row-gap: 20px">
                <div class="col engaged-training">
                    <div class="media-video">
                    </div>
                </div>
                <div class="col-sm-3 d-flex flex-column justify-content-between">
                    <div>
                        <p class="engaged_text engaged_text-bold">Atenção!</p>
                        <p class="engaged_text engaged_text-light"> Após assistir o vídeo completo o quiz é
                            liberado.</p>
                    </div>
                    <div class="engaged-quiz_card" [ngClass]="{'cursor-pointer':quizEnableStatus != 0}"
                         (click)="openQuiz()">
                        <div class="row align-items-center mb-2 mx-n2">
                            <div class="col-auto px-2">
                                <svg-icon src="assets/svg/quiz.svg"></svg-icon>
                            </div>
                            <div class="col px-2">
                                <p class="engaged-quiz_title mb-0">QUIZ</p>
                            </div>
                        </div>
                        <div class="engaged-quiz_block mt-4">
                            <p class="engaged-quiz_text mb-0">Prestou atenção no treinamento? Agora, bora responder
                                o
                                quiz!</p>
                            <div class="engaged-quiz_blur py-4" [ngClass]="{'d-none':quizEnableStatus != 0}">
                                <svg-icon src="assets/svg/blocked.svg"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <h1 class="home_title ">TIME PREPARADO</h1>
    <div class="row align-items-center">
        <div class="col-sm-6">
            <p class="engaged_text engaged_text-light engaged_text-lg">Você dá um gás nos treinamentos da sua
                revenda
                e a gente capricha nos prêmios da sua equipe!</p>
        </div>
        <div class="col-sm-3 offset-sm-2">
            <div class="important_card bg-tertiary">
                <p class="important_lbl">Importante!</p>
                <p class="important_value mb-4">Você só pontua se assistir ao vídeo completo e responder o Quiz dentro
                    do mês vigente.</p>
            </div>
        </div>
    </div>
</div>

<div class="bg-secondary pt-5">
    <div class="container">
        <div class="row" style="row-gap: 20px">
            <div class="col-sm-4">
                <div class="row align-items-end">
                    <div class="col-3 col-sm-4 col-lg-3">
                        <div class="team_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34.284" height="34.334"
                                 viewBox="0 0 34.284 34.334">
                                <path id="Caminho_178" data-name="Caminho 178"
                                      d="M597.157,303.94a.638.638,0,0,1,.638-.638h.034a.638.638,0,0,1,0,1.276h-.034A.638.638,0,0,1,597.157,303.94Zm-15.389-13.759h-4.36a.638.638,0,0,0,0,1.276h4.36a.638.638,0,0,0,0-1.276Zm-4.36-2.1h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm10.253,6.748h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm14.179,1.151v17.926a3.043,3.043,0,0,1-3.039,3.04H588.292l.368,3.274h1.214a1.169,1.169,0,0,1,1.167,1.168v1.344a1.169,1.169,0,0,1-1.167,1.168H579.52a1.168,1.168,0,0,1-1.167-1.168v-1.344a1.168,1.168,0,0,1,1.167-1.168h1.214l.368-3.274H570.594a3.043,3.043,0,0,1-3.039-3.04V285.861a3.043,3.043,0,0,1,3.039-3.041h.963v-.129a1.065,1.065,0,0,1,1.064-1.064h1.15v-1.034a1.148,1.148,0,0,1,1.147-1.147h6.909a3.5,3.5,0,0,0,5.739,0h6.909a1.148,1.148,0,0,1,1.147,1.147v1.034h1.151a1.065,1.065,0,0,1,1.063,1.064v.129h.964A3.043,3.043,0,0,1,601.839,285.861Zm-6.217,10.557h.939V283.6a.607.607,0,0,1,0-.279V282.9h-.939Zm-10.288-1.669a3.035,3.035,0,0,1,1.764-.563h7.248V280.722h-6.78a2.235,2.235,0,0,0-2.232,2.233Zm1.764.713a1.757,1.757,0,0,0-1.563.956h8.811v-.956Zm-12.051-1.276h7.248a3.034,3.034,0,0,1,1.764.563V282.955a2.235,2.235,0,0,0-2.232-2.233h-6.78Zm0,2.232h8.811a1.757,1.757,0,0,0-1.563-.956h-7.248Zm-2.214,0h.939V282.9h-.939Zm6.8,14.959V312.5h10.136v-1.127Zm7.747-1.276-.368-3.274h-4.622l-.368,3.274Zm13.188-7.84H568.83v1.526a1.766,1.766,0,0,0,1.764,1.764H598.8a1.766,1.766,0,0,0,1.764-1.764Zm0-16.4A1.766,1.766,0,0,0,598.8,284.1h-.964V296.63a1.065,1.065,0,0,1-1.063,1.064H572.621a1.065,1.065,0,0,1-1.064-1.064V284.1h-.963a1.766,1.766,0,0,0-1.764,1.765v15.124h31.733Z"
                                      transform="translate(-567.555 -279.446)" fill="#fff"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col-9 col-sm-8 col-lg-9">
                        <p class="team_title mb-0">Assistir o<br>Vídeo Completo</p>
                    </div>
                    <div class="col-lg-9 offset-lg-3 mt-3 mt-lg-0">
                        <p class="team_text"> 10 pontos</p>
                        <p class="team_text"> Participar dos treinamento disponibilizado
                            a cada mês.</p>
                    </div>
                </div>

            </div>
            <div class="col-sm-4">
                <div class="row align-items-end">
                    <div class="col-3 col-sm-4 col-lg-3">
                        <div class="team_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34.284" height="34.334"
                                 viewBox="0 0 34.284 34.334">
                                <path id="Caminho_178" data-name="Caminho 178"
                                      d="M597.157,303.94a.638.638,0,0,1,.638-.638h.034a.638.638,0,0,1,0,1.276h-.034A.638.638,0,0,1,597.157,303.94Zm-15.389-13.759h-4.36a.638.638,0,0,0,0,1.276h4.36a.638.638,0,0,0,0-1.276Zm-4.36-2.1h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm10.253,6.748h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm14.179,1.151v17.926a3.043,3.043,0,0,1-3.039,3.04H588.292l.368,3.274h1.214a1.169,1.169,0,0,1,1.167,1.168v1.344a1.169,1.169,0,0,1-1.167,1.168H579.52a1.168,1.168,0,0,1-1.167-1.168v-1.344a1.168,1.168,0,0,1,1.167-1.168h1.214l.368-3.274H570.594a3.043,3.043,0,0,1-3.039-3.04V285.861a3.043,3.043,0,0,1,3.039-3.041h.963v-.129a1.065,1.065,0,0,1,1.064-1.064h1.15v-1.034a1.148,1.148,0,0,1,1.147-1.147h6.909a3.5,3.5,0,0,0,5.739,0h6.909a1.148,1.148,0,0,1,1.147,1.147v1.034h1.151a1.065,1.065,0,0,1,1.063,1.064v.129h.964A3.043,3.043,0,0,1,601.839,285.861Zm-6.217,10.557h.939V283.6a.607.607,0,0,1,0-.279V282.9h-.939Zm-10.288-1.669a3.035,3.035,0,0,1,1.764-.563h7.248V280.722h-6.78a2.235,2.235,0,0,0-2.232,2.233Zm1.764.713a1.757,1.757,0,0,0-1.563.956h8.811v-.956Zm-12.051-1.276h7.248a3.034,3.034,0,0,1,1.764.563V282.955a2.235,2.235,0,0,0-2.232-2.233h-6.78Zm0,2.232h8.811a1.757,1.757,0,0,0-1.563-.956h-7.248Zm-2.214,0h.939V282.9h-.939Zm6.8,14.959V312.5h10.136v-1.127Zm7.747-1.276-.368-3.274h-4.622l-.368,3.274Zm13.188-7.84H568.83v1.526a1.766,1.766,0,0,0,1.764,1.764H598.8a1.766,1.766,0,0,0,1.764-1.764Zm0-16.4A1.766,1.766,0,0,0,598.8,284.1h-.964V296.63a1.065,1.065,0,0,1-1.063,1.064H572.621a1.065,1.065,0,0,1-1.064-1.064V284.1h-.963a1.766,1.766,0,0,0-1.764,1.765v15.124h31.733Z"
                                      transform="translate(-567.555 -279.446)" fill="#fff"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col-9 col-sm-8 col-lg-9">
                        <p class="team_title mb-0">Responder<br>ao Quiz </p>
                    </div>
                    <div class="col-lg-9 offset-lg-3 mt-3 mt-lg-0">
                        <p class="team_text"> 10 pontos</p>
                        <p class="team_text">Após cada vídeo, devem responder corretamente ao quiz para
                            pontuar.</p>
                    </div>
                </div>

            </div>
            <div class="col-sm-4">
                <div class="row align-items-end">
                    <div class="col-3 col-sm-4 col-lg-3">
                        <div class="team_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34.284" height="34.334"
                                 viewBox="0 0 34.284 34.334">
                                <path id="Caminho_178" data-name="Caminho 178"
                                      d="M597.157,303.94a.638.638,0,0,1,.638-.638h.034a.638.638,0,0,1,0,1.276h-.034A.638.638,0,0,1,597.157,303.94Zm-15.389-13.759h-4.36a.638.638,0,0,0,0,1.276h4.36a.638.638,0,0,0,0-1.276Zm-4.36-2.1h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm10.253,6.748h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm0-3.374h4.36a.638.638,0,0,0,0-1.276h-4.36a.638.638,0,0,0,0,1.276Zm14.179,1.151v17.926a3.043,3.043,0,0,1-3.039,3.04H588.292l.368,3.274h1.214a1.169,1.169,0,0,1,1.167,1.168v1.344a1.169,1.169,0,0,1-1.167,1.168H579.52a1.168,1.168,0,0,1-1.167-1.168v-1.344a1.168,1.168,0,0,1,1.167-1.168h1.214l.368-3.274H570.594a3.043,3.043,0,0,1-3.039-3.04V285.861a3.043,3.043,0,0,1,3.039-3.041h.963v-.129a1.065,1.065,0,0,1,1.064-1.064h1.15v-1.034a1.148,1.148,0,0,1,1.147-1.147h6.909a3.5,3.5,0,0,0,5.739,0h6.909a1.148,1.148,0,0,1,1.147,1.147v1.034h1.151a1.065,1.065,0,0,1,1.063,1.064v.129h.964A3.043,3.043,0,0,1,601.839,285.861Zm-6.217,10.557h.939V283.6a.607.607,0,0,1,0-.279V282.9h-.939Zm-10.288-1.669a3.035,3.035,0,0,1,1.764-.563h7.248V280.722h-6.78a2.235,2.235,0,0,0-2.232,2.233Zm1.764.713a1.757,1.757,0,0,0-1.563.956h8.811v-.956Zm-12.051-1.276h7.248a3.034,3.034,0,0,1,1.764.563V282.955a2.235,2.235,0,0,0-2.232-2.233h-6.78Zm0,2.232h8.811a1.757,1.757,0,0,0-1.563-.956h-7.248Zm-2.214,0h.939V282.9h-.939Zm6.8,14.959V312.5h10.136v-1.127Zm7.747-1.276-.368-3.274h-4.622l-.368,3.274Zm13.188-7.84H568.83v1.526a1.766,1.766,0,0,0,1.764,1.764H598.8a1.766,1.766,0,0,0,1.764-1.764Zm0-16.4A1.766,1.766,0,0,0,598.8,284.1h-.964V296.63a1.065,1.065,0,0,1-1.063,1.064H572.621a1.065,1.065,0,0,1-1.064-1.064V284.1h-.963a1.766,1.766,0,0,0-1.764,1.765v15.124h31.733Z"
                                      transform="translate(-567.555 -279.446)" fill="#fff"/>
                            </svg>
                        </div>
                    </div>
                    <div class="col-9 col-sm-8 col-lg-9">
                        <p class="team_title mb-0">Todos funcionários cadastrados</p>
                    </div>
                    <div class="col-lg-9 offset-lg-3 mt-3 mt-lg-0">
                        <p class="team_text"> 15 pontos</p>
                        <p class="team_text">Devem assitir ao vídeo do mês e responderam o quiz (independente de ter
                            acertado).</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="container mt-5">
    <div class="row">
        <div class="col-lg-6">
            <p class="engaged_text">
                Ao final de cada mês, será calculado o total de pontos obtido por todas as equipes cadastradas na
                sua revenda, dividido pelo máximo de pontos que pode ser atingido por todo o time. Este resultado
                será o percentual de participação da equipe da revenda.
            </p>
            <p class="engaged_text">
                Serão premiadas as 5 melhores revendas por região, sendo as primeiras colocadas de cada faixa,
                totalizando 20 revendas premiadas em cada período: Outubro/2022 à Novembro/2022 e Dezembro/2022 à
                Fevereiro/2023
            </p>
            <p class="engaged_text">
                Os vídeos dos meses anteriores ficarão disponíveis no site do Desafio. Assista mesmo se não for
                pontuar, pois a qualidade do atendimento vem sempre em primeiro lugar.
            </p>
        </div>
        <div class="col-lg-6">
            <div class="table-responsive">
                <table class="table table-mouth">
                    <thead>
                    <tr>
                        <td></td>
                        <th class="text-center" scope="col">OUT</th>
                        <th class="text-center" scope="col">NOV</th>
                        <th class="text-center" scope="col">DEZ</th>
                        <th class="text-center" scope="col">JAN</th>
                        <th class="text-center" scope="col">FEV</th>
                        <th class="text-center" scope="col">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            Assistir ao Video de Treinamento
                        </td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">50</td>
                    </tr>
                    <tr>
                        <td>
                            Acerta o Quiz
                        </td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">10</td>
                        <td class="text-center">50</td>
                    </tr>
                    <tr>
                        <td>
                            Toda e equipe da revenda assistir ao vídeo e responder ao quiz (mesmo que não acerte)
                        </td>
                        <td class="text-center">15</td>
                        <td class="text-center">15</td>
                        <td class="text-center">15</td>
                        <td class="text-center">15</td>
                        <td class="text-center">15</td>
                        <td class="text-center">75</td>
                    </tr>
                    <tr>
                        <td>
                            Pontos. Máximos/mês
                        </td>
                        <td class="text-center">35</td>
                        <td class="text-center">35</td>
                        <td class="text-center">35</td>
                        <td class="text-center">35</td>
                        <td class="text-center">35</td>
                        <td class="text-center">175</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!--        <ng-container *ngIf="modelUserView.toLowerCase() === 'ec' || modelUserView.toLowerCase() === 'ma'">-->
    <!--            <h3>Para Equipe Comercial e Master</h3>-->
    <!--        </ng-container>-->
    <!--        <ng-container *ngIf="getModelUserView(user) === 'rv'">-->
    <!--            <h3>Para Revenda e Equipe Revenda que seja gerente</h3>-->
    <!--            <a class="btn btn-block btn-secondary" (click)="showModal('modalAccumulated')">Veja Mais</a>-->
    <!--        </ng-container>-->
    <!--        <ng-container *ngIf="modelUserView.toLowerCase() === 'eq' && getModelUserView(user) !== 'rv'">-->
    <!--            <h3>Para Equipe Revenda</h3>-->
    <!--            <a class="btn btn-block btn-secondary" (click)="showModal('modalAccumulated')">Veja Mais</a>-->
    <!--        </ng-container>-->


    <h1 class="home_title home_title-lg mt-4"><span class="text-primary">Ranking</span> Time Preparado</h1>
    <div class="position-relative my-4">
        <div class="row h-100 mx-n1 justify-content-between align-content-center" style="row-gap: 30px">
            <ng-container *ngFor="let x of listOfMonths; let i = index;">
                <a class="px-1 position-relative" (click)="handlerRanking(x)">
                    <div class="home-training_btn" [ngClass]="{'active':x.id == rankingInput.month}">
                        <p class="home-training_lbl">{{x.name}}</p>
                        <p class="home-training_lbl home-training_lbl-sm">{{x.date}}</p>
                    </div>
                    <svg-icon class="home-training_arrow home-training_arrow-ranking"
                              [ngClass]="{'active':x.id == rankingInput.month}"
                              src="assets/svg/arrow-training-gray.svg"></svg-icon>
                </a>
                <a class="px-1 position-relative" (click)="handlerRanking(rankingTotalizer[0])" *ngIf="i === 1">
                    <div class="home-training_btn" [ngClass]="{'active': 99 === rankingInput.month}">
                        <p class="home-training_lbl">Período</p>
                        <p class="home-training_lbl home-training_lbl-sm">OUT à NOV/2022</p>
                    </div>
                    <svg-icon class="home-training_arrow home-training_arrow-ranking"
                              [ngClass]="{'active': 99 == rankingInput.month}"
                              src="assets/svg/arrow-training-gray.svg"></svg-icon>
                </a>
                <a class="px-1 position-relative" (click)="handlerRanking(rankingTotalizer[1])" *ngIf="i === 4">
                    <div class="home-training_btn" [ngClass]="{'active': 98 === rankingInput.month}">
                        <p class="home-training_lbl">Período</p>
                        <p class="home-training_lbl home-training_lbl-sm">DEZ/2022 à FEV/2023</p>
                    </div>
                    <svg-icon class="home-training_arrow home-training_arrow-ranking"
                              [ngClass]="{'active': 98 == rankingInput.month}"
                              src="assets/svg/arrow-training-gray.svg"></svg-icon>
                </a>
                <a class="px-1 position-relative" (click)="handlerRanking(rankingTotalizer[2])" *ngIf="i === 4">
                    <div class="home-training_btn" [ngClass]="{'active': 97 === rankingInput.month}">
                        <p class="home-training_lbl">Final</p>
                        <p class="home-training_lbl home-training_lbl-sm">OUT/2022 à FEV/2023</p>
                    </div>
                    <svg-icon class="home-training_arrow home-training_arrow-ranking"
                              [ngClass]="{'active': 97 == rankingInput.month}"
                              src="assets/svg/arrow-training-gray.svg"></svg-icon>
                </a>
            </ng-container>

        </div>
        <div class="home-training_bar home-training_bar-ranking">&nbsp;</div>
    </div>
</div>

<div class="py-5" style="margin-top: -6px;background-color: #efefef">
    <div class="container">
        <div class="row" style="row-gap: 20px">
            <div class="col-sm">
                <div class="engaged-ranking_card mb-4">
                    <div class="row">
                        <div class="col d-flex flex-column align-items-center">
                            <p class="engaged-ranking_lbl">Posição</p>
                            <p class="engaged-ranking_value mb-0">{{rankingMechanics.myPlacing}}°</p>
                        </div>
                        <div class="col d-flex flex-column align-items-center border-left border-white">
                            <p class="engaged-ranking_lbl">Porcentagem</p>
                            <p class="engaged-ranking_value mb-0">{{mathDecimal(rankingMechanics.percent)}}%</p>
                        </div>
                        <!--                        <div class="col-12 text-center">-->
                        <!--                            <p class="engaged-ranking_lbl mt-4 mb-0">O cálculo será apurado no fechamento do-->
                        <!--                                período.</p>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <a *ngIf="getModelUserView(user) === 'rv'||user.profile?.id == 'MA'||user.profile?.id == 'EC'"
                   routerLink="/ranking-list-extra"
                   class="btn btn-block btn-secondary">Veja sua pontuação detalhada</a>
            </div>
            <div class="col-sm offset-sm-1" *ngIf="!isNullOrUndefined(rankingMechanics.team)">

                <div class="row mb-3">
                    <div class="col-md-6">
                        <div class="input-material select mb-3">
                            <select [disabled]="!handlerRankingResaleBool" class="form-control"
                                    [(ngModel)]="this.rankingInput.region" (ngModelChange)="handlerRanking()"
                                    id="region" name="region">
                                <option *ngFor="let x of regionFindAll" [value]="x.name">{{x.name}}</option>
                            </select>
                            <label class="disabled" for="region">Regiao</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="input-material select mb-3">
                            <select [disabled]="!handlerRankingResaleBoolRange" class="form-control"
                                    [(ngModel)]="this.rankingInput.rangeId" (ngModelChange)="handlerRanking()"
                                    id="range" name="range">
                                <option *ngFor="let x of findAllRange" [value]="x.id">{{x.name}}</option>
                            </select>
                            <label class="disabled" for="range">Faixa</label>
                        </div>
                    </div>
                </div>

                <div class="engaged-ranking_list" *ngFor="let t of rankingMechanics.team">
                    <div class="row">
                        <div class="col-auto">
                            <p class="engaged-ranking_list_number">{{t.position}}º</p>
                        </div>
                        <div class="col">
                            <p class="engaged-ranking_list_name">{{t.name}}</p>
                        </div>
                        <div class="col-auto">
                            <p class="engaged-ranking_list_percentage">{{t.percent}}%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm offset-sm-1" *ngIf="isNullOrUndefined(rankingMechanics.team)">
                <p class="home_text d-flex justify-content-center align-items-center flex-column h-100">
                    Nenhum ranking encotrado para esse mês</p>
            </div>
        </div>
    </div>
</div>

<div class="container mt-5" *ngIf="getModelUserView(user) === 'rv'">
    <h1 class="home_title ">LISTA DE TREINAMENTO</h1>
    <div class="mt-3 table-responsive">
        <table class="table table-employees">
            <thead>
            <tr>
                <th scope="col"></th>
                <th class="text-center" scope="col"></th>
                <th class="text-center" scope="col">OUT</th>
                <th class="text-center" scope="col">NOV</th>
                <th class="text-center" scope="col">DEZ</th>
                <th class="text-center" scope="col">JAN</th>
                <th class="text-center" scope="col">FEV</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th class="text-center">
                    <span>Total de pontos</span>
                </th>
                <th class="text-center"></th>
                <th class="text-center">
                    {{trainingEmployees.totalOctober}} pts
                </th>
                <th class="text-center">
                    {{trainingEmployees.totalNovember}} pts
                </th>
                <th class="text-center">
                    {{trainingEmployees.totalDecember}} pts
                </th>
                <th class="text-center">
                    {{trainingEmployees.totalJanuary}} pts
                </th>
                <th class="text-center">
                    {{trainingEmployees.totalFebruary}} pts
                </th>
            </tr>
            <tr class="border-bottom" *ngFor="let x of dataUser.users|
            paginate:{id:'users',itemsPerPage: dataUser.PageSize,
            currentPage: dataUser.currentPage, totalItems: dataUser.totalCount }">
                <td>{{x.name}}</td>
                <td class="text-center">
                    {{x.profileId}}
                </td>
                <td class="text-center">
                    <div class="d-flex justify-content-center no-gutters align-items-center">
                        <svg-icon [ngClass]="{'icon-success':x.octoberVideo}" src="assets/svg/video.svg"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.octoberQuiz}"
                                  src="assets/svg/answer-quiz.svg" class="mx-2"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.octoberQuizTeam}" src="assets/svg/team.svg"></svg-icon>
                        <span class="ml-2 flex-shrink-0">{{x.octoberTotal}} pts</span>
                    </div>
                </td>
                <td class="text-center">
                    <div class="d-flex justify-content-center no-gutters align-items-center">
                        <svg-icon [ngClass]="{'icon-success':x.novemberVideo}" src="assets/svg/video.svg"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.novemberQuiz}"
                                  src="assets/svg/answer-quiz.svg" class="mx-2"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.novemberQuizTeam}" src="assets/svg/team.svg"></svg-icon>
                        <span class="ml-2 flex-shrink-0">{{x.novemberTotal}} pts</span>
                    </div>
                </td>
                <td class="text-center">
                    <div class="d-flex justify-content-center no-gutters align-items-center">
                        <svg-icon [ngClass]="{'icon-success':x.decemberVideo}" src="assets/svg/video.svg"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.decemberQuiz}"
                                  src="assets/svg/answer-quiz.svg" class="mx-2"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.decemberQuizTeam}" src="assets/svg/team.svg"></svg-icon>
                        <span class="ml-2 flex-shrink-0">{{x.decemberTotal}} pts</span>

                    </div>
                </td>
                <td class="text-center">
                    <div class="d-flex justify-content-center no-gutters align-items-center">
                        <svg-icon [ngClass]="{'icon-success':x.januaryVideo}" src="assets/svg/video.svg"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.januaryQuiz}"
                                  src="assets/svg/answer-quiz.svg" class="mx-2"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.januaryQuizTeam}" src="assets/svg/team.svg"></svg-icon>
                        <span class="ml-2 flex-shrink-0">{{x.januaryTotal}} pts</span>
                    </div>
                </td>
                <td class="text-center">
                    <div class="d-flex justify-content-center no-gutters align-items-center">
                        <svg-icon [ngClass]="{'icon-success':x.februaryVideo}" src="assets/svg/video.svg"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.februaryQuiz}"
                                  src="assets/svg/answer-quiz.svg" class="mx-2"></svg-icon>
                        <svg-icon [ngClass]="{'icon-success':x.februaryQuizTeam}" src="assets/svg/team.svg"></svg-icon>
                        <span class="ml-2 flex-shrink-0">{{x.februaryTotal}} pts</span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div class="row">
        <div class="col">
            <dl class="row mb-4">
                <dt class="col-auto engaged_lbl">Legenda</dt>
                <dd class="col d-flex" style="gap: 32px">
                    <div class="d-flex align-items-center mx-n2">
                        <span class="px-2 engaged_value">Video</span>
                        <div class="px-2">
                            <svg-icon class="engaged_icon" src="assets/svg/video.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mx-n2">
                        <span class="px-2 engaged_value">Quiz</span>
                        <div class="px-2">
                            <svg-icon class="engaged_icon" src="assets/svg/answer-quiz.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="d-flex align-items-center mx-n2">
                        <span class="px-2 engaged_value">Equipe engajada</span>
                        <div class="px-2">
                            <svg-icon class="engaged_icon" src="assets/svg/team.svg"></svg-icon>
                        </div>
                    </div>
                </dd>
            </dl>
        </div>
        <div class="col-auto">
            <app-pagination-custom [id]="'users'" (pageChange)="pageChanged($event)"></app-pagination-custom>
        </div>
    </div>
</div>

<div class="modal fade" id="modalAccumulated" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title">
                    ACUMULADO REVENDA
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <div class="bg-secondary table-responsive">
                    <table class="table table-striped more-table">
                        <thead>
                        <tr>
                            <th class="text-left">Entregador</th>
                            <th class="text-left w-15">Código</th>
                            <th class="text-right w-20">Cupons</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let i of [].constructor(20)">
                            <td class="text-left">CLAUDIONOR DA SILVA</td>
                            <td class="text-left">19</td>
                            <td class="text-right">34 Cupons</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="modalQuest" data-keyboard="false" data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title">
                    QUIZ
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body p-0">
                <div class="quiz_card">
                    <div class="row" style="row-gap: 10px">
                        <div class="col-sm-auto">
                            <div class="quiz_number">{{quizQuestion.current}}</div>
                        </div>
                        <div class="col">
                            <p class="quiz_quest">{{quizQuestion.question?.question}}</p>
                        </div>
                    </div>
                </div>
                <ng-template [ngIf]="isNullOrUndefined(this.quizAnswerDto)">
                    <div class="modal-body">
                        <div class="row align-items-center quiz_check" *ngFor="let a of quizQuestion.answers">
                            <div class="col-auto">
                                <input id="{{a.id}}" class="form-check-input"
                                       type="radio" name="answerId" (change)="this.quizAnswerInput.answerId = a.id">
                                <label class="quiz_input form-check-label" for="{{a.id}}">&nbsp;</label>
                            </div>
                            <div class="col">
                                <p class="quiz_answer mb-0">{{a.answer}}</p>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col">
                                <button data-dismiss="modal" aria-label="Close"
                                        class="btn btn-block btn-outline-secondary">
                                    Cancelar
                                </button>
                            </div>
                            <div class="col">
                                <button (click)="onSave()" class="btn btn-block btn-secondary btn-disabled"
                                        [disabled]="isNullOrUndefined(this.quizAnswerInput.answerId)">Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngIf]="!isNullOrUndefined(this.quizAnswerDto)">
                    <div class="modal-body">
                        <div class="row align-items-center quiz_check " *ngFor="let a of quizAnswerDto.answer">
                            <div class="col-auto">
                                <svg-icon *ngIf="a.correct" class="quiz_correct"
                                          src="assets/svg/check-circle.svg"></svg-icon>

                                <svg-icon *ngIf="!a.correct" src="assets/svg/cancel.svg"></svg-icon>
                            </div>
                            <div class="col">
                                <p class="quiz_answer">{{a.answer}}</p>
                                <p *ngIf="!isNullOrUndefined(a.comment)"
                                   class="quiz_answer quiz_answer-comment mb-0">{{a.comment}}</p>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col">
                                <a data-dismiss="modal" aria-label="Close" class="btn btn-block btn-outline-secondary">Cancelar</a>
                            </div>
                            <div class="col">
                                <a (click)="onNextQuiz()" class="btn btn-block btn-secondary">Próximo</a>
                            </div>
                        </div>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalQuestFinish" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width:666px" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title">
                    QUIZ
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <h4 class="quiz_title mb-5">{{handlerQuiz() ? 'Parabéns!' : 'Não foi dessa vez!'}}</h4>
                <div class="row align-items-center px-sm-5" style="row-gap:30px">
                    <div class="col-sm-auto d-flex align-items-center justify-content-center">
                        <div class="quiz_icon">
                            <ng-lottie [options]="handlerQuiz() ? trophyJson : oasJson"></ng-lottie>
                        </div>
                    </div>
                    <div class="col-sm offset-sm-1">
                        <div class="row mb-3">
                            <div class="col-4" *ngFor="let q of quizAnswerFinish.questions">
                                <div class="quiz_number position-relative">
                                    <span>{{q.questionNumber}}</span>
                                    <svg-icon *ngIf="q.correct" class="quiz_hit quiz_hit-success"
                                              src="assets/svg/check-circle.svg"></svg-icon>
                                    <svg-icon *ngIf="!q.correct" class="quiz_hit"
                                              src="assets/svg/cancel.svg"></svg-icon>
                                </div>
                            </div>
                        </div>
                        <p class="quiz_text mb-0">{{quizAnswerFinish.message}}</p>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="col-sm-9">
                        <a data-dismiss="modal" aria-label="Close" class="btn btn-block btn-secondary">Fechar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalTrainingFinish" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalTrainingFinishLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width:666px" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title">
                    TREINAMENTO
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <h4 class="quiz_title mb-5">Parabéns!</h4>
                <div class="row align-items-center px-sm-5" style="row-gap:30px">
                    <div class="col-sm-auto d-flex align-items-center justify-content-center" *ngIf="trainingWinner">
                        <div class="quiz_icon">
                            <ng-lottie [options]="trophyJson"></ng-lottie>
                        </div>
                    </div>
                    <div class="col-sm offset-sm-1">
                        <p class="quiz_text mb-0">{{trainingMessage}}</p>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="col-sm-9">
                        <a data-dismiss="modal" aria-label="Close" class="btn btn-block btn-secondary">Fechar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="chat">
    <div class="chat_header">
        <div class="row align-items-center">
            <div class="col-auto">
                <svg id="Grupo_163" data-name="Grupo 163" xmlns="http://www.w3.org/2000/svg" width="28"
                     height="28" viewBox="0 0 28 28">
                    <path id="União_5" data-name="União 5" d="M2,0V6.786A14,14,0,1,0,14,0Z"
                          transform="translate(28 28) rotate(180)" fill=" #50a7db"/>
                    <line id="Linha_33" data-name="Linha 33" x2="15.75"
                          transform="translate(6.125 11.375)"
                          fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                    <line id="Linha_34" data-name="Linha 34" x2="15.75"
                          transform="translate(6.125 16.625)"
                          fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                </svg>
            </div>
            <div class="col">
                <h4 class="chat_title mb-0">Comentários</h4>
            </div>
        </div>
    </div>
    <div class="chat_body">
        <ng-container *ngFor="let x of chat;let i = index">
            <div class="row mx-n2 align-items-end my-3"
                 [ngClass]="{'flex-row-reverse':isNullOrUndefined(x.userAdmin)}">
                <div class="col-auto px-2">
                    <img class="chat_photo"
                         [ngClass]="{'border-secondary':isNullOrUndefined(x.userAdmin)}"
                         src="{{ !isNullOrUndefined(x.userAdmin) ? 'assets/images/logo-dark.png' : isNullOrUndefined(x.userImagePath) ?
                                         'assets/images/nophoto.png':
                                         getImageURL() + 'user/image/' + x.userImagePath}}"
                         alt="logo-chat">
                </div>
                <div class="col-8 px-2">
                    <div class="chat_bubbly"
                         [ngClass]="isNullOrUndefined(x.userAdmin)?'chat_bubbly-user':'chat_bubbly-admin'">
                        <p class="chat_bubbly_message">{{x.message}}</p>
                        <p class="chat_bubbly_date mb-0">{{x.createdAt |date:'dd/MM/yyyy às HH:mm'}}</p>
                    </div>
                </div>
            </div>
        </ng-container>
        <p class="chat_not-found mb-0"
           *ngIf="isNullOrUndefined(chat)">
            Nenhuma mensagem encontrada
        </p>
    </div>
    <div class="chat_footer">
        <ng-container
                *ngIf="!isNullOrUndefined(this.status) && this.status.toLowerCase() !== 'revisar';else statusSend">
        </ng-container>
        <ng-template #statusSend>
            <div class="position-relative"
                 *ngIf="isNullOrUndefined(chat)|| !isNullOrUndefined(chat.slice(-1)[0].userAdmin);else message">
                <div id="idMessage" contenteditable="true"
                     data-text="Digite sua mensagem" class="chat_input"></div>
                <a class="cursor-pointer" (click)="onSend('idMessage')">
                    <svg-icon class="chat_send" src="assets/svg/send.svg"></svg-icon>
                </a>
            </div>
            <ng-template #message>
                <span class="text-white text-center d-block">aguarde a resposta do administrador</span>
            </ng-template>
        </ng-template>
    </div>
</div>


<ng-container *ngIf="!isNullOrUndefined(standardization)">
    <div *ngIf="!standardizationPage" style="background-color: #F2F2F2">
        <div class="container">
            <div class="row">
                <div class="col-sm-5">
                    <div class="py-4 px-sm-5">
                        <p class="technical-detail_description mb-0">
                            A Revenda pontuará se comprovar, por meio de fotos, a padronização da Fachada e da Frota,
                            atendendo aos itens descritos abaixo:
                        </p>
                    </div>
                </div>
                <div class="col-sm-6 offset-lg-1 bg-tertiary">
                    <div class="h-100 py-3 px-4">
                        <div class="row mx-n4 align-items-center h-100">
                            <div class="col px-4">
                                <div class="row justify-content-center align-items-center" style="row-gap: 17px;">
                                    <div class="col-auto">
                                        <p class="technical-detail_point_value technical-detail_point_value-sm mb-0">
                                            Revenda</p>
                                    </div>
                                    <div class="col-auto">
                                        <p class="technical-detail_point_value technical-detail_point_value-sm mb-0">
                                            200</p>
                                        <p class="technical-detail_point_lbl mb-0">pontos</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col px-4 border-left border-white">
                                <div class="row justify-content-center align-items-center" style="row-gap: 17px;">
                                    <div class="col-auto">
                                        <p class="technical-detail_point_value technical-detail_point_value-sm mb-0">
                                            Frota</p>
                                    </div>
                                    <div class="col-auto">
                                        <p class="technical-detail_point_value technical-detail_point_value-sm mb-0">
                                            50</p>
                                        <p class="technical-detail_point_lbl mb-0">pontos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row my-5" style="row-gap:20px">
            <div class="col-sm-6 col-lg-5">
                <div class="row mx-n1 align-items-center mb-4">
                    <div class="col-auto px-1">
                        <div class="technical-detail_bar">&nbsp;</div>
                    </div>
                    <div class="col px-1">
                        <h5 class="technical-detail_title">Fachada<span> | 1 Cupom + 200 pontos da Visita Técnica</span></h5>
                    </div>
                </div>
                <p class="technical-detail_text mb-5">
                    As fotos devem considerar pintura de muros, portões, gradil e placa de fachada (testeira). Todos os
                    itens não podem conter a antiga marca da Mazelabs, seja com endosso BR Petrobras, seja com a grafia
                    antiga.
                </p>
            </div>
            <div class="col-sm-6 offset-lg-1">
                <div class="row mx-n1 align-items-center mb-4">
                    <div class="col-auto px-1">
                        <div class="technical-detail_bar">&nbsp;</div>
                    </div>
                    <div class="col px-1">
                        <h5 class="technical-detail_title">Frota<span> | 1 Cupom + 50 pontos da Visita Técnica</span></h5>
                    </div>
                </div>
                <p class="technical-detail_text mb-5">
                    Inserir até 20 Imagens que comprovem a correta padronização da frota da Revenda segundo o manual de
                    identidade visual da marca Mazelabs, podendo ser pick-up, moto ou caminhão na parte frontal e
                    lateral. Importante que nas imagens também apareçam as placas dos veículos, para que fique evidente
                    a padronização de toda a frota, caso a Revenda possua mais de um veículo de aparência similar. Todos
                    os itens não podem conter a antiga marca da Mazelabs, seja com endosso BR Petrobras, seja com a
                    grafia antiga.
                </p>
            </div>
            <div class="col-12">
                <p><strong>A revenda que for aprovada até a data 11/12/2022 ganhará um cupom extra.</strong></p>
            </div>
            <div class="col-sm-6 col-lg-7">
                <div class="standardization_card mb-4">
                    <h4 class="standardization_card_title mb-3">Revenda Matriz</h4>
                    <div class="row my-4" style="row-gap:20px">
                        <div class="col-sm-6">
                            <a class="btn w-100 h-100 align-text-center btn-outline-primary"
                               [ngClass]="{'btn-branch': isNullOrUndefined(gasStation) ? false : !gasStation}"
                               (click)="handlerGasStation(standardization,false)">
                                <div class="row justify-content-center align-items-center h-100">
                                    <div class="col-auto">
                                        <span>Revenda</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6">
                            <a class="btn w-100 h-100 align-text-center btn-outline-primary"
                               [ngClass]="{'btn-branch': gasStation}"
                               (click)="handlerGasStation(standardization,true)">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-auto">
                                        <span>Postos ou Supermercados</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <dl class="col-auto">
                            <dt class="standardization_lbl">CNPJ</dt>
                            <dd class="standardization_value">{{standardization.cnpj|mask:'00.000.000/0000-00'}}</dd>
                        </dl>
                        <dl class="col">
                            <dt class="standardization_lbl">Razão Social</dt>
                            <dd class="standardization_value">{{standardization.company}}</dd>
                        </dl>
                    </div>
                    <ng-container *ngIf="standardization.isFacade">
                        <h5 class="standardization_card_subtitle mb-3">Fachada</h5>
                        <div class="row mb-4" style="row-gap: 30px">
                            <div *ngFor="let t of standardization.resaleFacade.typeImage"
                                 class="col-6 col-lg-3">
                                <ng-container *ngIf="!isNullOrUndefined(t.path)">
                                    <div class="technical-detail_download">
                                        <a href="{{getImageURL()+ 'technical-visit/image/' + t.path}}"
                                           target="_blank">
                                            <img class="technical-detail_download_img" alt="img"
                                                 src="{{getImageURL()+ 'technical-visit/image/' + t.path}}">
                                        </a>
                                        <a (click)="removeFile(t)" *ngIf="!validateUploadFileItem(t.status)"
                                           class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                            <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                        </a>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="isNullOrUndefined(t.path)">
                                    <a class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2"
                                       (click)="handleUploadStandard(t.id,
                                   standardization.resaleFacade.technicalVisitItemId)">
                                        <svg-icon class="d-block mb-2"
                                                  src="assets/svg/download-secondary.svg"></svg-icon>
                                        <span class="mb-0">{{enumStandardization(t.name)}}</span>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="standardization.isFleet">
                        <h5 class="standardization_card_subtitle mb-3">Frota</h5>
                        <div class="row" style="row-gap: 30px">
                            <div *ngFor="let t of standardization.resaleFleet.items" class="col-6 col-lg-3">
                                <div class="technical-detail_download">
                                    <a href="{{getImageURL()+ 'technical-visit/image/' + t.path}}"
                                       target="_blank">
                                        <img class="technical-detail_download_img" alt="img"
                                             src="{{getImageURL()+ 'technical-visit/image/' + t.path}}">
                                    </a>

                                    <a (click)="removeFileFleet(t)" *ngIf="!validateUploadFileItem(t.status)"
                                       class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                        <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3" *ngIf="standardization.resaleFleet.items.length < 20">
                                <a class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2"
                                   (click)="handleUploadStandard(standardization.resaleFleet.typeImage[0].id,
                                   standardization.resaleFleet.technicalVisitItemId)">
                                    <svg-icon class="d-block mb-2"
                                              src="assets/svg/download-secondary.svg"></svg-icon>
                                    <span class="mb-0">Adicionar</span>
                                </a>
                            </div>

                        </div>
                    </ng-container>
                </div>
                <div class="border-bottom pb-5 mb-5" *ngFor="let f of standardization!.companyBranch;let i = index">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <div class="standardization_number">
                                <span>{{i + 1}}</span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <dl class="col-auto">
                                    <dt class="standardization_lbl">CNPJ</dt>
                                    <dd class="standardization_value">{{f.sonCNPJ}}</dd>
                                </dl>
                                <dl class="col">
                                    <dt class="standardization_lbl">Razão Social</dt>
                                    <dd class="standardization_value">{{f.sonCompanyName}}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div class="row my-4 align-items-center">
                        <div class="col-auto">
                            <p class="standardization_quest mb-0">Qual o tipo de sua filial?</p>
                        </div>
                        <div class="col">
                            <div class="row" style="row-gap:20px">
                                <div class="col-sm-6">
                                    <a class="btn w-100 h-100 align-text-center btn-outline-primary"
                                       [ngClass]="{'btn-branch': isNullOrUndefined(f.gasStation) ? false : !f.gasStation}"
                                       (click)="handlerGasStation(f,false)">
                                        <div class="row justify-content-center align-items-center h-100">
                                            <div class="col-auto">
                                                <span>Revenda</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="col-sm-6">
                                    <a class="btn w-100 h-100 align-text-center btn-outline-primary"
                                       [ngClass]="{'btn-branch': f.gasStation}"
                                       (click)="handlerGasStation(f,true)">
                                        <div class="row justify-content-center align-items-center">
                                            <div class="col-auto">
                                                <span>Postos ou Supermercados</span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!isNullOrUndefined(f.gasStation)">
                        <ng-container *ngIf="f.isFacade">
                            <h5 class="standardization_card_subtitle mb-3">Fachada</h5>
                            <div class="row mb-4" style="row-gap: 30px">
                                <div *ngFor="let t of f.resaleFacade.typeImage" class="col-6 col-lg-3">
                                    <ng-container *ngIf="!isNullOrUndefined(t.path)">
                                        <div class="technical-detail_download">
                                            <a href="{{getImageURL()+ 'technical-visit/image/' + t.path}}"
                                               target="_blank">
                                                <img class="technical-detail_download_img" alt="img"
                                                     src="{{getImageURL()+ 'technical-visit/image/' + t.path}}">
                                            </a>
                                            <a (click)="removeFile(t)" *ngIf="!validateUploadFileItem(t.status)"
                                               class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                                <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                            </a>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="isNullOrUndefined(t.path)">
                                        <a class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2"
                                           (click)="handleUploadStandard(t.id,f.resaleFacade.technicalVisitItemId)">
                                            <svg-icon class="d-block mb-2"
                                                      src="assets/svg/download-secondary.svg"></svg-icon>
                                            <span class="mb-0">{{enumStandardization(t.name)}}</span>
                                        </a>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="f.isFleet">
                            <h5 class="standardization_card_subtitle mb-3">Frota</h5>
                            <div class="row" style="row-gap: 30px">
                                <div *ngFor="let t of f.resaleFleet.items" class="col-6 col-lg-3">
                                    <div class="technical-detail_download">
                                        <a href="{{getImageURL()+ 'technical-visit/image/' + t.path}}"
                                           target="_blank">
                                            <img class="technical-detail_download_img" alt="img"
                                                 src="{{getImageURL()+ 'technical-visit/image/' + t.path}}">
                                        </a>
                                        <a (click)="removeFileFleet(t)" *ngIf="!validateUploadFileItem(t.status)"
                                           class="btn btn-secondary rounded-circle technical-detail_download_trash">
                                            <svg-icon src="assets/svg/trash.svg"></svg-icon>
                                        </a>
                                    </div>

                                </div>
                                <div class="col-6 col-lg-3" *ngIf="f.resaleFleet.items.length < 20">
                                    <a class="btn btn-sm btn-block btn-outline-secondary hover-stroke pt-4 pb-2"
                                       (click)="handleUploadStandard(standardization.resaleFleet.typeImage[0].id,f.resaleFleet.technicalVisitItemId)">
                                        <svg-icon class="d-block mb-2"
                                                  src="assets/svg/download-secondary.svg"></svg-icon>
                                        <span class="mb-0">Adicionar</span>
                                    </a>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>
                </div>
                <ng-container *ngIf="standardizationPage">
                    <div class="row justify-content-center" *ngIf="!validateUploadFile">
                        <div class="col-6">
                            <a (click)="saveStandardization.emit()" class="btn btn-block btn-secondary" *ngIf="false" style="cursor: default;">Enviar
                                Relatório</a>
                            <a class="btn btn-block btn-secondary">Prazo Encerrado</a>
                        </div>
                    </div>
                </ng-container>

            </div>
            <div class="col-sm-6 col-lg-5">
                <app-chat [(chat)]="standardization.chat"
                          [itemId]="standardization.resaleFleet.technicalVisitItemId"
                          [status]="status"></app-chat>

            </div>
        </div>
    </div>
</ng-container>

<app-modal-gas-station [gasStationInput]="gasStationInput" [id]="'modalGasStationInternal'"
                       (eventSend)="handlerChildren.emit()"></app-modal-gas-station>


<app-crop-image [cropId]="'modalCropStandardization'"
                [cropAspectRatio]="4/3"
                [cropResizeToWidth]="800"
                [cropResizeToHeight]="600"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>

<div class="page_header container-hero">
    <div class="container-xxl p-0">
        <p class="page_header_bg">Prêmios</p>
        <div class="container">
            <h2 class="page_header_title">Prêmios</h2>
        </div>
    </div>
</div>

<div class="container">

    <div class="my-5">
        <h3 class="text-secondary text-center">Entenda como <span
                class="text-primary font-weight-bold">funcionam as premiações</span></h3>

        <p class="text-center">Descubra como serão divididas as premiações para cada grupo da campanha</p>

        <div class="prize_tab">
            <div class="prize_tab_button" (click)="scrollToPanel('.js-revenda')">
                <img src="assets/images/logo-white.png" alt="Revenda" class="prize_tab_button-thumb" />
                <span>Revenda</span>
            </div>
            <div class="prize_tab_button" (click)="scrollToPanel('.js-equipe-revenda')">
                <div class="img_animation">
                    <img src="assets/images/logo-white.png" alt="Revenda" class="prize_tab_button-thumb" />
                </div>
                <span>Equipe Revenda</span>
            </div>
        </div>
    </div>


    <!--<div [ngSwitch]="getModelUserView(user)">
        <ng-container *ngSwitchCase="'rv'">
            <ng-container *ngTemplateOutlet="prizesRV"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'eq'">
            <ng-container *ngTemplateOutlet="prizesEQ"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <ng-container *ngTemplateOutlet="prizesRV"></ng-container>
        </ng-container>
    </div>-->

</div>

<!--REVENDA | GERENTE-->
<div class="js-revenda">
    <div class="bg-white py-4">
        <div class="container">
            <div id="revenda" class="row">
                <div class="col-12">
                    <h2 class="prize_title mb-5">REVENDA</h2>
                </div>
                <div class="offset-md-1 col-md-11">
                    <h3 class="prize_subtitle mb-0 text-primary">Ranking Final<br>
                        <span>Mazelabs</span></h3>
                    <p class="mb-4">Confira as premiações do Ranking Geral por faixa e classificação.</p>
                    <div class="table-responsive">
                        <table class="table table-prize">
                            <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"><span>1º</span></th>
                                <th scope="col"><span>2º</span></th>
                                <th scope="col"><span>3º</span></th>
                                <th scope="col"><span> 4º ao 6º</span></th>
                                <th scope="col"><span>7º ao 10º</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Faixa 1</th>
                                <td> R$ 8.000,00</td>
                                <td> R$ 7.000,00</td>
                                <td> R$ 6.000,00</td>
                                <td> R$ 4.000,00</td>
                                <td> R$ 2.000,00</td>
                            </tr>
                            <tr>
                                <th>Faixa 2</th>
                                <td> R$ 12.000,00</td>
                                <td> R$ 10.000,00</td>
                                <td> R$ 8.000,00</td>
                                <td> R$ 6.000,00</td>
                                <td> R$ 4.000,00</td>
                            </tr>
                            <tr>
                                <th>Faixa 3</th>
                                <td> R$ 18.000,00</td>
                                <td> R$ 15.500,00</td>
                                <td> R$ 13.500,00</td>
                                <td> R$ 11.500,00</td>
                                <td> R$ 9.000,00</td>
                            </tr>
                            <tr>
                                <th>Faixa 4</th>
                                <td> R$ 22.500,00</td>
                                <td> R$ 20.000,00</td>
                                <td> R$18.000,00</td>
                                <td> R$ 16.000,00</td>
                                <td> R$ 12.500,00</td>
                            </tr>
                            <tr>
                                <th>Faixa 5</th>
                                <td> R$ 28.000,00</td>
                                <td> R$ 23.500,00</td>
                                <td> R$ 21.500,00</td>
                                <td> R$ 19.500,00</td>
                                <td> R$ 16.000,00</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div >
        <div class="container">
            <div class="position-relative py-5">
                <div class="row step_height">
                    <div class="offset-md-1 col-md-11 d-flex flex-column justify-content-between">
                        <div>
                            <h3 class="prize_subtitle mb-0 text-primary">Ranking Final<br>
                                <span>KIT Desafio</span></h3>
                        </div>
                        <div class="row justify-content-end">
                            <div class="col-lg-12">
                                <p>Premiação para os primeiros colocados, sendo 1 por faixa por região, totalizando 20 ganhadores.</p>
                                <div class="table-responsive">
                                    <table class="table table-prize">
                                        <thead>
                                        <tr>
                                            <th scope="col" class="w-20"></th>
                                            <th scope="col"><span>IMAS</span></th>
                                            <th scope="col"><span>PANOS DE PRATO</span></th>
                                            <th scope="col"><span>AVENTAL</span></th>
                                            <th scope="col"><span>SACOLA</span></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>Faixa 1</th>
                                            <td>300</td>
                                            <td>100</td>
                                            <td>50</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <th>Faixa 2</th>
                                            <td>1.000</td>
                                            <td>400</td>
                                            <td>100</td>
                                            <td>100</td>
                                        </tr>
                                        <tr>
                                            <th>Faixa 3</th>
                                            <td>2.000</td>
                                            <td>800</td>
                                            <td>150</td>
                                            <td>150</td>
                                        </tr>
                                        <tr>
                                            <th>Faixa 4</th>
                                            <td>6.000</td>
                                            <td>1.200</td>
                                            <td>300</td>
                                            <td>300</td>
                                        </tr>
                                        <tr>
                                            <th>Faixa 5</th>
                                            <td>12.000</td>
                                            <td>2.500</td>
                                            <td>600</td>
                                            <td>600</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-graydark py-5">
        <div class="container">
            <div class="row">
                <div class="col-md-11 offset-md-1">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="prize_subtitle mb-5 text-primary">Time
                                <span class="text-secondary">Preparado</span>
                            </h3>
                            <p class="mb-3">Os <b class="text-primary">1º Colocados</b> de cada faixa ganharão</p>
                            <div class="prize-card">
                                <span class="d-block mb-3">Periodo</span>
                                <div class="row">
                                    <div class="col-md-5">
                                        <b class="text-primary">Outubro</b>
                                        <p class="text-secondary">2022</p>
                                    </div>
                                    <div class="col-md-1">
                                        <div class="divider">&nbsp;</div>
                                    </div>
                                    <div class="col-md-5">
                                        <b class="text-primary">Fevereiro</b>
                                        <p class="text-secondary">2023</p>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-4"><small>A quantidade de Kits de uniformes será entregue conforme a Faixa da
                                Revenda, de acordo com tabela:</small></p>
                            <div class="table-responsible">
                                <table class="table table-prize table-prize-small">
                                    <thead>
                                    <tr>
                                        <th colspan="2"><span>KITS UNIFORMES</span></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th><span>FAIXA 1</span></th>
                                        <td>3 Kits Uniformes</td>
                                    </tr>
                                    <tr>
                                        <th><span>FAIXA 2</span></th>
                                        <td>6 Kits Uniformes</td>
                                    </tr>
                                    <tr>
                                        <th><span>FAIXA 3</span></th>
                                        <td>9 Kits Uniformes</td>
                                    </tr>
                                    <tr>
                                        <th><span>FAIXA 4</span></th>
                                        <td>12 Kits Uniformes</td>
                                    </tr>
                                    <tr>
                                        <th><span>FAIXA 5</span></th>
                                        <td>24 Kits Uniformes</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container">
        <div class="position-relative py-5">
            <h2 class="prize_title mb-0">MECÂNICA <span>EXTRA</span></h2>
        </div>
    </div>

    <div class="bg-white">
        <div class="container">
            <div class="pb-5">
                <div class="row">
                    <div class="offset-md-1 col-md-11">
                        <h3 class="prize_subtitle">Padronização<br><span>da Revenda</span></h3>
                        <div class="row justify-content-between align-items-center">
                            <p class="col-12 mb-5">Ao comprovar a padronização da sua revenda e filiais, você receberá 1 cupom para concorrer ao sorteio. Comprovando também a padronização da sua frota e das filiais, receberá 1 cupom extra. E têm mais, enviando toda as fotos da padronização até o dia 11/12/2022, sua revenda ganha mais 1 cupom aumentando as chances de ganhar.</p>
                            <!--<div class="col-sm-auto">
                                <img src="assets/images/cell.png" alt="">
                            </div>-->
                        </div>

                        <p class="mt-4">
                            <strong>Atenção:</strong> Só concorrem ao sorteio de padronização as revendas que possuem fachadas. Revendas sem fachada (exemplo de postos de combustíveis e supermercados) não concorrem ao sorteio.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="panel-stockcar">
        <div class="container">
            <div class="row">
                <div class="offset-md-1 col-md-11">
                    <div class="row">
                        <div class="col-md-9 bg-white">
                            <div class="pb-5 pr-md-4">
                                <h3 class="prize_subtitle mb-5">Plataforma Aceleraí<br>
                                    <span>e Multiplica</span></h3>

                                <h4 class="prize_text-highlight">Um privilégio para os melhores!</h4>
                                <p>Serão 4 revendas sorteadas (1 por região) com 1 super ingresso VIP para assistir uma
                                    das etapas da Mazelabs com tudo pago, 1 Kit incrível composto por por 1 boné, 1
                                    camiseta e 1 sacochila personalizadas. Além de R$ 500,00 para utilizar como quiser
                                    no dia da corrida.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>




<!--EQUIPE REVENDA-->
<div id="equipeRevenda" class="js-equipe-revenda" style="position: relative; overflow: hidden;">

    <div class="bg-white py-5">
        <div class="container">
            <h2 class="prize_title mb-5">EQUIPE <span>REVENDA</span></h2>
            <div class="row">
                <div class="offset-md-1 col-md-11">
                    <h3 class="prize_subtitle mb-5">Time <span>Preparado</span></h3>
                    <p>
                        Para participar da Mecânica Extra - Time Preparado, os revendedores e suas equipes precisam realizar os treinamentos e responder ao quiz mensalmente, quanto maior o percentual de participação da equipe, mais pontos serão ganhos e melhor pode ser a colocação da revenda no ranking do período vigente.
                        <br>
                        Todos os funcionários cadastrados das revendas que ficarem em <b class="text-primary">1º colocado</b> de cada faixa, por região, ganharão em dois períodos:
                    </p>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="d-flex align-items-center">
                                <div class="prize-card w-100">
                                    <span>Periodo</span>
                                    <b class="text-primary mt-3">
                                        Outubro
                                    </b>
                                    <p class="text-secondary">2022</p>
                                    <div class="divider-row my-3"></div>
                                    <b class="text-primary">
                                        Novembro
                                    </b>
                                    <p class="text-secondary">2022</p>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="d-flex align-items-center">
                                <div class="prize-card w-100">
                                    <span>Periodo</span>
                                    <b class="text-primary mt-3">
                                        Dezembro
                                    </b>
                                    <p class="text-secondary">2022</p>
                                    <div class="divider-row my-3"></div>
                                    <b class="text-primary">
                                        Fevereiro
                                    </b>
                                    <p class="text-secondary">2023</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="position-relative py-5">
            <div class="row step_height step_height-equip">

                <div class="offset-md-1 col-lg-4">
                    <h3 class="prize_subtitle mb-5">Ranking Final</h3>
                    <p>Os funcionários das 200 revendas vencedoras do ranking ganharão R$ 300,00, desde que cadastrados
                        na
                        campanha até 15/02/2023.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-secondary pt-5" *ngIf="false">
        <div class="container">
            <div class="row">
                <div class="offset-md-1 col-md-11">
                    <h3 class="prize_subtitle">Entregador Copa na Cozinha</h3>
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <img src="assets/images/entregador.png" alt="Entregador Copa na Cozinha" class="prize_brand-primary" />
                        </div>
                        <div class="col-md-3">
                            <p class="text-white">
                                Serão premiados os
                                <b>
                                    500 entregadores
                                </b>
                                com mais cadastros da promoção <b>Copa na Cozinha</b>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="isProfile" tabindex="-1" role="dialog"
     aria-labelledby="loginLabel" aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <div class="modal-title modal_title">
                    <strong>{{!isValidateProfileBool ? 'Meu Perfil' : 'Primeiro acesso'}}</strong>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill':'white'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mb-4" style="row-gap: 10px">
                    <div class="col">
                        <a class="modal_tab" [ngClass]="{'select': step === 1}" (click)="step = 1">
                            Meus Dados
                        </a>
                    </div>
                    <div class="col" *ngIf="getModelUserView(user) === 'rv' || getModelUserView(user) === 'eq'">
                        <a class="modal_tab" [ngClass]="{'select': step === 2}" (click)="step = 2">
                            Dados da Revenda
                        </a>
                    </div>
                    <div class="col-12" *ngIf="getModelUserView(user) === 'rv' || getModelUserView(user) === 'eq'">
                        <div class="modal_tab_bar position-relative">
                            <div class="select" [ngClass]="step === 1?'left':'right'">&nbsp;</div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="step == 1">
                    <form autocomplete="off" class="row mx-n1 mx-sm-n3 align-items-center">
                        <div class="col-auto px-1 px-sm-3 col-lg-6 mb-3" style="text-align: -webkit-center;">
                            <div class="profile_photo"
                                 [ngClass]="{'cursor-pointer':!isNullOrUndefined(user.activatedAt)}"
                                 (click)="showModal('modalCropLogin')">
                                <ng-container *ngIf="isNullOrUndefined(croppedImage)">
                                    <img class="profile-image-image" id="svg_login_profile"
                                         src="{{isNullOrUndefined(user.pathImage) ? 'assets/images/nophoto.png' :
                                         this.image_url + 'user/image/' + user.pathImage}}"
                                         alt="photo"/>
                                </ng-container>
                                <img class="profile-image-image" *ngIf="croppedImage" src="{{croppedImage}}" alt=""/>
                                <div *ngIf="!isValidateProfileBool" class="profile_edit">
                                    <svg id="edit_black_24dp" xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                         viewBox="0 0 22 22">
                                        <path id="Caminho_201" data-name="Caminho 201" d="M0,0H22V22H0Z" fill="none"/>
                                        <path id="Caminho_202" data-name="Caminho 202"
                                              d="M3,14v2.894H5.894l8.535-8.535L11.535,5.461ZM16.667,6.117a.769.769,0,0,0,0-1.088L14.862,3.223a.769.769,0,0,0-1.088,0L12.361,4.636,15.255,7.53l1.412-1.412Z"
                                              transform="translate(1.055 1.054)" fill="#032246"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="col px-1 px-sm-3 col-lg-6 mb-3">
                            <dl class="row align-items-end no-gutters">
                                <dt class="col-sm-auto modal_value mb-0 pr-2">Gerência:</dt>
                                <dd class="col-sm modal_lbl mb-0 text-secondary">{{gmr.name}}</dd>
                            </dl>
                            <dl class="row align-items-end no-gutters">
                                <dt class="col-sm-auto modal_value mb-0 pr-2">Região:</dt>
                                <dd class="col-sm modal_lbl mb-0 text-secondary">{{getRegion(gmr.name)}}</dd>
                            </dl>
                            <dl class="row align-items-end no-gutters" *ngIf="!isNullOrUndefined(range.name)">
                                <dt class="col-sm-auto modal_value mb-0 pr-2">Faixa:</dt>
                                <dd class="col-sm modal_lbl mb-0 text-secondary">{{range.name}}</dd>
                            </dl>
                            <a *ngIf="!isValidateProfileBool" (click)="showModal('changePassword')"
                               class="btn d-flex justify-content-sm-between btn-tertiary w-100 mt-3">
                                <svg-icon src="assets/svg/key.svg"></svg-icon>
                                <span>Alterar Senha</span>
                                <svg-icon [svgStyle]="{'fill':' #50a7db'}" src="assets/svg/key.svg"></svg-icon>
                            </a>
                        </div>

                        <div class="col-lg-6">
                            <div class="input-material">
                                <input type="text" class="form-control" autocomplete="off" id="nameProfile"
                                       maxlength="100"
                                       [(ngModel)]="user.name" name="name" required/>
                                <label for="nameProfile">Nome Completo*</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input class="form-control" autocomplete="off" type="text" id="role" name="role"
                                       [(ngModel)]="profile.name" disabled required/>
                                <label class="disabled" for="role">Cargo*</label>
                            </div>
                        </div>

                        <div class="col-lg-3">
                            <div class="input-material">
                                <input type="text" class="form-control" id="cpfProfile" autocomplete="off"
                                       [disabled]="getModelUserView(user) === 'eq' || !isNullOrUndefined(user.activatedAt)"
                                       maxlength="100" name="cpf" [dropSpecialCharacters]="false" mask="000.000.000-00"
                                       [(ngModel)]="user.cpf" required/>
                                <label [ngClass]="{'disabled' : !isNullOrUndefined(user.activatedAt)}" for="cpfProfile">CPF*</label>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="input-material input-material-sm">
                                <input class="form-control" autocomplete="off" type="text" id="birthdayProfile"
                                       name="birthday"
                                       [mask]="'d0/M0/0000'"
                                       [(ngModel)]="user.birthday"
                                       [dropSpecialCharacters]="false"
                                       required/>
                                <label for="birthdayProfile">Data de Nascimento*</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input type="text" class="form-control" id="cell" autocomplete="off"
                                       [(ngModel)]="user.phone"
                                       mask="(00) 0000-0000||(00) 0 0000-0000" maxlength="100" name="cell" required/>
                                <label for="cell">Telefone com DDD*</label>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="input-material">
                                <input type="text" class="form-control" id="emailProfile" maxlength="100" name="email"
                                       [(ngModel)]="user.email" autocomplete="off" required/>
                                <label for="emailProfile">E-mail*</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input type="text" class="form-control" autocomplete="off" id="confimEmailProfile"
                                       [(ngModel)]="emailConfirm"
                                       maxlength="100"
                                       name="confimEmailProfile" required/>
                                <label for="confimEmailProfile">Confirmar E-mail*</label>
                            </div>
                        </div>
                        <ng-container *ngIf="isValidateProfileBool">
                            <div class="col-lg-6">
                                <div class="input-material">
                                    <input type="password" class="form-control" id="passwordProfile" maxlength="100"
                                           name="passwordProfile"
                                           [(ngModel)]="newPassword" autocomplete="off" required/>
                                    <label for="passwordProfile">Nova Senha*</label>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-material">
                                    <input type="password" class="form-control" id="confirmNewPassword" maxlength="100"
                                           name="confirmNewPassword"
                                           [(ngModel)]="newPasswordConfirm" autocomplete="off" required/>
                                    <label for="confirmNewPassword">Confirmar Senha*</label>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center justify-content-lg-center">
                                    <label class="switch mb-0 mr-2">
                                        <input type="checkbox" name="checkbox" [(ngModel)]="modelTerms" />
                                        <span class="slider round"></span>
                                    </label>
                                    <!--<label class="custom-checkbox mb-0 mr-2">
                                        <input type="checkbox" name="checkbox" [(ngModel)]="modelTerms" />
                                    </label>-->
                                    <span>Aceitar <a href="/regulamento" class="text-primary" target="_blank">regulamento</a></span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="d-flex align-items-center">
                                    <label class="switch mb-0 mr-2">
                                        <input type="checkbox" name="checkbox1" [(ngModel)]="modelPolicy" />
                                        <span class="slider round"></span>
                                    </label>
                                    <!--<label class="custom-checkbox mb-0 mr-2">
                                        <input type="checkbox" name="checkbox1" [(ngModel)]="modelPolicy" />
                                    </label>-->
                                    <span>Aceitar <a href="/politica" class="text-primary" target="_blank">políticas de privacidade</a></span>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12">
                            <h6 class="text-white">( * ) Campos obrigatórios.</h6>
                        </div>
                    </form>
                </ng-container>
                <ng-container *ngIf="step == 2">
                    <form autocomplete="off" class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input [(ngModel)]="this.range.name" type="text"
                                       autocomplete="off" class="form-control" id="corporate_name"
                                       maxlength="200" name="corporate_name" disabled required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(range.name)}"
                                       for="corporate_name">Volume de Compras de GLP P13/mês</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input type="text" autocomplete="off" class="form-control" id="companyName"
                                       [(ngModel)]="user.companyName"
                                       maxlength="200" name="companyName" disabled required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(user.companyName)}" for="companyName">Razão
                                    Social</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input type="text" autocomplete="off" class="form-control" id="fantasyName"
                                       [(ngModel)]="user.fantasyName"
                                       maxlength="200" name="fantasyName" disabled required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(user.fantasyName)}" for="fantasyName">Nome
                                    Fantasia</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input type="text" autocomplete="off" class="form-control" id="cnpjFather"
                                       [(ngModel)]="user.cnpjFather" name="cnpjFather" disabled required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(user.cnpjFather)}" for="cnpjFather">CNPJ</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input type="text" autocomplete="off" class="form-control" id="GME"
                                       [(ngModel)]="gmr.name"
                                       maxlength="200" name="GME" disabled required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(gmr.name)}" for="GME">GME</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input type="text" autocomplete="off" class="form-control" id="fatherCode"
                                       [(ngModel)]="user.fatherCode"
                                       maxlength="200" name="fatherCode" disabled required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(user.fatherCode)}" for="fatherCode">Código
                                    de Cliente (R3)</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material disabled">
                                <input type="text" autocomplete="off" class="form-control" id="consultant"
                                       [(ngModel)]="user.consultant"
                                       maxlength="200" name="consultant" disabled required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(user.consultant)}" for="consultant">Consultor
                                    Responsável</label>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="input-material">
                                <input type="text" autocomplete="off" class="form-control" id="resellerPhone"
                                       [(ngModel)]="user.phone" disabled
                                       maxlength="100" name="resellerPhone" mask="(00) 0000-0000||(00) 0 0000-0000"
                                       required/>
                                <label [ngClass]="{'disabled': !isNullOrUndefined(user.phone)}" for="resellerPhone">Telefone
                                    Revenda (Celular / Fixo)*</label>
                            </div>
                        </div>
                    </form>
                </ng-container>
                <div class="row">
                    <div class="col-6">
                        <a data-dismiss="modal" aria-label="Close"
                           class="btn btn-block btn-outline-secondary">Cancelar</a>
                    </div>
                    <div class="col-6">
                        <a (click)="onSave()" class="btn btn-block btn-primary">Salvar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="changePassword" tabindex="-1" role="dialog" aria-labelledby="loginLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header modal_header">
                <h3 class="modal-title modal_title">
                    Alterar Senha
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon [svgStyle]="{'fill': '#fff'}" src="assets/svg/close-circle.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <h5 class="modal_lbl modal_lbl-lg">Preencha os campos abaixo para alterar sua senha.</h5>

                <div class="my-4 px-5">
                    <div class="input-material mb-4">
                        <input type="password" [(ngModel)]="changePassword.password" class="form-control"
                               id="passwordProfile2"
                               name="password" maxlength="100"
                               required/>
                        <label for="passwordProfile2">Senha atual</label>
                    </div>
                    <div class="input-material mb-4">
                        <input type="password" [(ngModel)]="changePassword.newPassword" class="form-control"
                               id="newPassword" name="newPassword" maxlength="100"
                               required/>
                        <label for="newPassword">Nova senha</label>
                    </div>
                    <div class="input-material mb-4">
                        <input type="password" [(ngModel)]="newPasswordConfirm" class="form-control"
                               id="confirmNewPassword2" name="confirmNewPassword2"
                               maxlength="100" required/>
                        <label for="confirmNewPassword2">Confirme nova senha</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <a href="javascript:void(0);" class="btn btn-outline-secondary btn-block" data-dismiss="modal"
                           aria-label="Close">
                            Cancelar
                        </a>
                    </div>
                    <div class="col-6">
                        <a href="javascript:void(0);" class="btn btn-secondary btn-block" (click)="onSavePassword()">
                            Alterar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-crop-image [cropId]="'modalCropLogin'"
                [cropAspectRatio]="1"
                [cropResizeToWidth]="576"
                [cropResizeToHeight]="576"
                (croppedImageResult)="setCroppedImage($event)"></app-crop-image>

<ng-container *ngIf="true">
    <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="false">
    <div class="login_bg">
        <div class="container container_login">
            <div class="row no-gutters align-items-center" style="min-height: 100vh">
                <div class="col d-none d-lg-block">
                    <img style="width: 80%; max-width: 709px" src="assets/images/logo.png" alt="logo">
                </div>
                <div class="col-lg-3 login_col">
                    <div class="d-flex flex-column align-items-center" style="margin-bottom: 66px">
                        <img src="assets/images/logo-login.png" alt="logo">
                    </div>
                    <p class="login_lbl text-center mb-4">EM BREVE</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
